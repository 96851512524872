<template>
  <div class="mt-2" v-loading="loadingData">
    <div class="title_resultsPatsnt">
      <div class="title_resultsPatsnt_text">
        {{ $t("message.patient_hospitalization") }}
      </div>
      <el-button
        v-if="lastHistory.is_ended != 0"
        icon="el-icon-edit"
        v-can="'patientHistories.update'"
        disabled
        @click="edit()"
        type="primary"
        round
        >{{ $t("message.update") }}</el-button
      >
      <el-button
        v-else
        icon="el-icon-edit"
        v-can="'patientHistories.update'"
        @click="edit()"
        type="primary"
        round
        >{{ $t("message.update") }}</el-button
      >
    </div>

    <div class="p-3 pt0">
      <el-row :gutter="30">
        <el-col :span="16">
          <div class="userPasten_info">
            <div class="title_pp">{{ $t("message.number_card") }}:</div>
            <div class="name_h">{{ model.number }}</div>
          </div>
        </el-col>
        <!-- end el-col -->

        <el-col :span="8">
          <div class="userPasten_info">
            <div class="title_pp">{{ $t("message.date_hospitalization") }}</div>
            <div class="name_h">{{ model.date_gos }}</div>
          </div>
        </el-col>
        <!-- end el-col -->

        <el-col :span="16">
          <div class="userPasten_info">
            <div class="title_pp">{{ $t("message.diagnos") }}</div>
            <div class="name_h">{{ model.diagnos }}</div>
          </div>
        </el-col>
        <!-- end el-col -->

        <el-col :span="8">
          <div class="userPasten_info">
            <div class="title_pp">{{ $t("message.doctor") }}</div>
            <div class="name_h">
              {{
                model.doctor
                  ? model.doctor.name +
                    " " +
                    model.doctor.surname +
                    " " +
                    (model.doctor ? model.doctor.patronymic : "")
                  : ""
              }}
            </div>
          </div>
        </el-col>
        <!-- end el-col -->

        <el-col :span="8">
          <div class="userPasten_info">
            <div class="title_pp">
              {{ $t("message.purpose_of_hospitalization") }}
            </div>
            <div class="name_h">
              {{
                model.purposeHospitalization
                  ? model.purposeHospitalization.name
                  : ""
              }}
            </div>
          </div>
        </el-col>
        <!-- end el-col -->

        <el-col :span="8">
          <div class="userPasten_info">
            <div class="title_pp">{{ $t("message.puls") }}</div>
            <div class="name_h">
              {{ model.impuls_one }} - {{ model.impuls_two }}
            </div>
          </div>
        </el-col>
        <!-- end el-col -->

        <el-col :span="8">
          <div class="userPasten_info">
            <div class="title_pp">{{ $t("message.temperature") }}</div>
            <div class="name_h">{{ model.teperatura }}</div>
          </div>
        </el-col>
        <!-- end el-col -->

        <el-col :span="8">
          <div class="userPasten_info">
            <div class="title_pp">{{ $t("message.condition") }}</div>
            <div class="name_h">
              {{ model.injuryStatus ? model.injuryStatus.name : "" }}
            </div>
          </div>
        </el-col>
        <!-- end el-col -->

        <el-col :span="8">
          <div class="userPasten_info">
            <div class="title_pp">{{ $t("message.weight") }}</div>
            <div class="name_h">{{ model.weight }}</div>
          </div>
        </el-col>
        <!-- end el-col -->

        <el-col :span="8">
          <div class="userPasten_info">
            <div class="title_pp">{{ $t("message.height") }}</div>
            <div class="name_h">{{ model.height }}</div>
          </div>
        </el-col>
        <!-- end el-col -->

        <el-col :span="8">
          <div class="userPasten_info">
            <div class="title_pp">{{ $t("message.type_of_injury") }}</div>
            <div class="name_h">
              {{ model.injuryType ? model.injuryType.name : "" }}
            </div>
          </div>
        </el-col>
        <!-- end el-col -->

        <el-col :span="8">
          <div class="userPasten_info">
            <div class="title_pp">{{ $t("message.date_injury") }}</div>
            <div class="name_h">{{ model.date_injury }}</div>
          </div>
        </el-col>
        <!-- end el-col -->

        <el-col :span="8">
          <div class="userPasten_info">
            <div class="title_pp">{{ $t("message.date_register") }}</div>
            <div class="name_h">{{ model.date_register }}</div>
          </div>
        </el-col>
        <!-- end el-col -->
      </el-row>
      <!-- end el-row -->

      <el-col :span="24">
        <div class="userPasten_info">
          <div class="title_pp">{{ $t("message.allergy") }}</div>
          <div class="name_h">{{ model.allergy }}</div>
        </div>
      </el-col>
      <!-- end el-col -->
    </div>

    <el-tabs type="border-card" class="mt-5">
      <el-tab-pane label="Направление">
        <el-row :gutter="30">
          <el-col :span="8">
            <div class="userPasten_info">
              <div class="title_pp">{{ $t("message.partner_clinic") }}:</div>
              <div class="name_h">
                {{ model.partnerClinic ? model.partnerClinic.name : "" }}
              </div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="userPasten_info">
              <div class="title_pp">{{ $t("message.department") }}:</div>
              <div class="name_h">
                {{ model.department ? model.department.name : "" }}
              </div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="userPasten_info">
              <div class="title_pp">{{ "Партнёр доктор" }}:</div>
              <div class="name_h">
                {{ model.partnerDoctor ? model.partnerDoctor.name : "" }}
              </div>
            </div>
          </el-col>
          <!-- end el-col -->
        </el-row>
      </el-tab-pane>
      <el-tab-pane :label="$t('message.add_to_room')">
        <el-row :gutter="30">
          <el-col :span="8">
            <div class="userPasten_info">
              <div class="title_pp">{{ $t("message.class") }}:</div>
              <div class="name_h">
                {{
                  model.patientSchedule
                    ? model.patientSchedule.bunk.classs.name
                    : ""
                }}
              </div>
            </div>
          </el-col>
          <!-- end el-col -->
          <el-col :span="8">
            <div class="userPasten_info">
              <div class="title_pp">{{ $t("message.corps") }}:</div>
              <div class="name_h">
                {{
                  model.patientSchedule
                    ? model.patientSchedule.bunk.corps.name
                    : ""
                }}
              </div>
            </div>
          </el-col>
          <!-- end el-col -->

          <el-col :span="8">
            <div class="userPasten_info">
              <div class="title_pp">{{ $t("message.floor") }}:</div>
              <div class="name_h">
                {{
                  model.patientSchedule
                    ? model.patientSchedule.bunk.floor.name
                    : ""
                }}
              </div>
            </div>
          </el-col>
          <!-- end el-col -->
          <el-col :span="8">
            <div class="userPasten_info">
              <div class="title_pp">{{ $t("message.room") }}:</div>
              <div class="name_h">
                {{
                  model.patientSchedule
                    ? model.patientSchedule.bunk.hospitalRoom.name
                    : ""
                }}
              </div>
            </div>
          </el-col>
          <!-- end el-col -->
          <el-col :span="8">
            <div class="userPasten_info">
              <div class="title_pp">{{ $t("message.bunk") }}:</div>
              <div class="name_h">
                {{
                  model.patientSchedule ? model.patientSchedule.bunk.name : ""
                }}
              </div>
            </div>
          </el-col>
          <!-- end el-col -->

          <el-col :span="8">
            <div class="userPasten_info">
              <div class="title_pp">{{ $t("message.arrival_date") }}:</div>
              <div class="name_h">
                {{ model.patientSchedule ? model.patientSchedule.start : "" }}
              </div>
            </div>
          </el-col>
          <!-- end el-col -->
          <el-col :span="8">
            <div class="userPasten_info">
              <div class="title_pp">{{ $t("message.departure_date") }}:</div>
              <div class="name_h">
                {{ model.patientSchedule ? model.patientSchedule.end : "" }}
              </div>
            </div>
          </el-col>
          <!-- end el-col -->
        </el-row>
      </el-tab-pane>
      <el-tab-pane :label="$t('message.pockets')">
        <div style="width: 100%" class="user-kod d-flex flex-column">
          <el-row
            v-if="
              model.patientHistoryPocket && model.patientHistoryPocket.pocket
            "
          >
            <el-col :sm="24">
              <div class="userPasten_info m-flex-column">
                <div class="title_pp">{{ $t("message.description") }}:</div>
                <!-- <div class="name_h">{{ model.patientHistoryPocket.pocket.description }}</div> -->
                <div
                  class="name_h"
                  v-html="model.patientHistoryPocket.pocket.description"
                ></div>
              </div>
            </el-col>

            <el-col :sm="24" v-if="model.patientSchedule">
              <div class="userPasten_info m-flex-column">
                <div class="title_pp">
                  {{ $t("message.price_one_day_without_room_price") }}:
                </div>
                <div class="name_h">
                  {{ model.patientHistoryPocket.pocket.price | formatMoney }}
                </div>
              </div>
            </el-col>

            <el-col :sm="24" v-if="model.patientSchedule">
              <div class="userPasten_info m-flex-column">
                <div class="title_pp">
                  {{ $t("message.price_one_day_min_room_price") }}:
                </div>
                <div class="name_h">
                  {{
                    lowPriceFilter(
                      model.patientHistoryPocket.pocket.pocket_rooms,
                      model.patientHistoryPocket.pocket.price
                    ) | formatMoney
                  }}
                </div>
              </div>
            </el-col>

            <el-col :sm="24" v-if="model.patientSchedule">
              <div class="userPasten_info m-flex-column">
                <div class="title_pp">
                  {{
                    $t("message.price_with_room_price", {
                      days: getDays,
                    })
                  }}:
                </div>
                <div class="name_h">
                  {{
                    (lowPriceFilter(
                      model.patientHistoryPocket.pocket.pocket_rooms,
                      model.patientHistoryPocket.pocket.price
                    ) *
                      getDays)
                      | formatMoney
                  }}
                </div>
              </div>
            </el-col>

            <!-- necci kun yotishi -->
          </el-row>
        </div>
      </el-tab-pane>
    </el-tabs>

    <el-drawer
      size="100%"
      :wrapperClosable="false"
      :visible.sync="drawer.update.status"
      :ref="drawer.update.name"
      @opened="drawerOpened(drawer.update.component)"
      @closed="drawerClosed(drawer.update.component)"
    >
      <crm-update
        :selected="selectedModel"
        :ref="drawer.update.component"
        :drawer-name="drawer.update.name"
      ></crm-update>
    </el-drawer>
  </div>
</template>

<script>
import CrmUpdate from "./../../patientHistory/components/crm-update";
import drawer from "@/utils/mixins/drawer";
import { mapActions, mapGetters } from "vuex";
import list from "@/utils/mixins/list";
export default {
  mixins: [list, drawer],
  props: ["selected"],
  name: "patientHome",
  components: {
    CrmUpdate,
  },
  data() {
    return {
      loadingData: false,
      selectedModel: {},
      filterForm: {},
      drawer: {
        update: {
          name: "update",
          status: false,
          component: "componentDrawerUpdate",
        },
      },
      model: {},
    };
  },
  computed: {
    getId() {
      return this.$route.params.id;
    },
    getDays() {
      if (this.model.patientSchedule)
        return Math.ceil(
          Math.abs(
            new Date(this.model.patientSchedule.end) -
              new Date(this.model.patientSchedule.start)
          ) /
            (1000 * 3600 * 24)
        );
    },
    ...mapGetters({
      activeCode: "hospitalPatients/activeCode",
      list: "patientHistories/list",
      columns: "patientHistories/columns",
      pagination: "patientHistories/pagination",
      filter: "patientHistories/filter",
      sort: "patientHistories/sort",
      lastHistory: "dailyTreatments/lastHistory",
    }),
    actions: function () {
      return ["edit", "delete"];
    },
  },
  watch: {
    lastHistory: {
      handler: function (val, oldVal) {
        this.loadingData = true;

        this.editModel({
          hospital_patient_id: this.getId,
          id: val.id,
        })
          .then((res) => {
            this.loadingData = false;
            this.model = res.data.patientHistory;
          })
          .catch((err) => {
            this.loadingData = false;
            this.$notify({
              title: "Ошибка",
              type: "error",
              offset: 130,
              message: err.error.message,
            });
          });
      },
    },
  },
  async mounted() {
    var vm = this;
    this.editModel({
      hospital_patient_id: vm.getId,
      id: this.lastHistory.id,
    })
      .then((res) => {
        this.model = res.data.patientHistory;
      })
      .catch((err) => {
        this.$notify({
          title: "Ошибка",
          type: "error",
          offset: 130,
          message: err.error.message,
        });
      });
  },
  beforeRouteLeave(to, from, next) {
    this.empty();
    this.emptyHospital();
    next();
  },
  methods: {
    ...mapActions({
      hospitalPatient: "hospitalPatients/index",
      updateList: "patientHistories/index",
      updateSort: "patientHistories/updateSort",
      updateFilter: "patientHistories/updateFilter",
      updateColumn: "patientHistories/updateColumn",
      updatePagination: "patientHistories/updatePagination",
      editModel: "patientHistories/show",
      empty: "patientHistories/empty",
      emptyHospital: "hospitalPatients/empty",
      delete: "patientHistories/destroy",
      refreshData: "patientHistories/refreshData",
    }),
    fetchData() {
      this.loadingData = true;
      var vm = this;
      this.editModel({
        hospital_patient_id: vm.getId,
        id: this.lastHistory.id,
      })
        .then((res) => {
          this.loadingData = false;
          this.model = res.data.patientHistory;
        })
        .catch((err) => {
          this.loadingData = false;
          this.$notify({
            title: "Ошибка",
            type: "error",
            offset: 130,
            message: err.error.message,
          });
        });
    },
    lowPriceFilter(val, price) {
      return (
        parseFloat(Math.min(...val.map((item) => item.price))) +
        parseFloat(price)
      );
    },
    refresh() {
      this.refreshData()
        .then((res) => {
          this.filterForm = JSON.parse(JSON.stringify(this.filter));
        })
        .catch((err) => {});
    },

    edit() {
      this.selectedModel = this.model;
      this.drawer.update.status = true;
    },

    destroy(model) {
      this.delete(model.id)
        .then((res) => {
          this.$notify({
            title: "Успешно",
            type: "success",
            offset: 130,
            message: res.message,
          });
          this.fetchData();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    emptyModel() {
      this.empty();
    },
  },
};
</script>
