<template>
  <div id="app" v-loading="saving" >
    <scheduler id="dp" :config="initConfig" ref="scheduler"></scheduler>
  </div>
</template>

<script>
import scheduler from "@/components/dayPilot/scheduler";
// import scheduler from "./../../components/dayPilot/scheduler";
import { DayPilot } from "daypilot-pro-vue";
import { mapGetters, mapActions } from "vuex";
import store from '@/store';

export default {
  components: { scheduler },
    computed: {
    ...mapGetters({
        list: 'schedulesCabinets/list',
        dataroom: 'hospitalRooms/dataroom',
        saving: "schedulesCabinets/saving",
    }),
    
    actions: function() {
      return ['edit', 'delete'];
    },
    // DayPilot.Scheduler object
    // https://api.daypilot.org/daypilot-scheduler-class/
    scheduler: function() {
      return this.$refs.scheduler.control;
    }
  },

  data() {
    return {
      timestamp: "",
      initConfig: {
        treePreventParentUsage: true,
        timeHeaders: [{ groupBy: "Month" }, { groupBy: "Day", format: "d" }],
        scale: "Day",
        height: 600,
        heightSpec: "Max",
        days: 732, //DayPilot.Date.today().daysInMonth(),
        startDate: "2023-01-01", //DayPilot.Date.today().firstDayOfMonth(),
        eventHeight: 40,
        separators: [
          {
            color: "Red",
            location: new DayPilot.Date.today(),
            layer: "BelowEvents"
          }
        ],
        allowEventOverlap: false,
        onTimeRangeSelected: function(args) {
              var dp = this;
              DayPilot.Modal.prompt("Забронировать койку", "Пациент").then(function(
                modal
              ) {
                dp.clearSelection();
                if (!modal.result) {
                  return;
                }
                  let form = {};
                  form.start= args.start,
                  form.end = args.end,
                  form.id =  DayPilot.guid(),
                  form.resource = args.resource,
                  form.bunk_id = args.resource,
                  form.text = modal.result;
                  form.color = "#66c33a";
                  
                  store.dispatch('schedulesCabinets/store',form)
                    .then(res => {
                      console.log(res);
                      store.dispatch('schedulesCabinets/index')
                      })
                    .catch(err => {
                      console.log(err);
                    })
                  dp.message("Койка Пациента Создана");
              });
        },
        eventMoveHandling: "Update",
        onEventMoved: function(args) {
            let form = {};
            form.start= args.newStart,
            form.end = args.newEnd,
            form.id = args.e.cache.id,
            form.resource = args.newResource,
            form.bunk_id = args.newResource,
            form.text = args.e.cache.text;
            form.color = args.e.cache.color;
            store.dispatch('schedulesCabinets/update',form)
              .then(res => {
                  store.dispatch('schedulesCabinets/index')
                  console.log(res);
                })
              .catch(err => {
                console.log(err);
              })
            this.message("Койка Пациента Перемещена");
        },
        eventDeleteHandling: "Update",
        onEventDeleted: function (args) {
          store.dispatch('schedulesCabinets/destroy', args.e.cache.id)
              .then(res => {
                  store.dispatch('schedulesCabinets/index')
                  store.dispatch('schedulesCabinets/empty')
                  console.log(res);
                  this.timeRangeSelectedHandling = "Enabled";
              }).catch(err => {
                console.log(err);
              })
          this.message("Койка Пациента Удалена: " + args.e.text());
        },
        eventResizeHandling: "Update",
        onEventResized: function(args) {
          let form = {};
          form.start= args.newStart,
          form.end = args.newEnd,
          form.id = args.e.cache.id,
          form.resource = args.e.cache.resource,
          form.bunk_id = args.e.cache.resource,
          form.text = args.e.cache.text;
          form.color = args.e.cache.color;
          store.dispatch('schedulesCabinets/update',form)
            .then(res => {
                store.dispatch('schedulesCabinets/index')
                console.log(res);
              })
            .catch(err => {
              console.log(err);
            })
          this.message("Койка Пациента Изменена");
        },
        eventClickHandling: "Disabled",
        eventHoverHandling: "Disabled",
        treeEnabled: true,
        treePreventParentUsage: true,
        onBeforeEventRender: function(args) {
          if (store.getters['patientHistories/model'] || store.getters['schedulesCabinets/bunk']) {
            let hp1 = store.getters['schedulesCabinets/bunk'];
            let hp = store.getters['patientHistories/model'];   
            if ((hp && hp.patientSchedule && hp.patientSchedule.id == args.e.id) || (hp1 && hp1.id == args.e.id)) {
                this.timeRangeSelectedHandling = "Disabled";
                args.data.barColor = args.data.color;
                args.data.areas = [
                  {
                    top: 6,
                    right: 2,
                    icon: "icon-triangle-down",
                    visibility: "Hover",
                    action: "ContextMenu",
                    style:
                      "font-size: 14px; background-color: #f9f9f9; border: 1px solid #ccc; padding: 2px 2px 0px 2px; cursor:pointer;"
                  }
                ];
            }else{
                args.e.moveDisabled = true;
                args.e.rightClickDisabled  = true;
                args.e.deleteDisabled = true;
                args.e.doubleClickDisabled  = true;
                args.e.clickDisabled = true;
                args.e.resizeDisabled = true;
                args.e.cssClass = "disabled";
                args.e.barColor = "#9a0";
                args.e.barBackColor = "#ac0";
                args.e.bubbleHtml = "Moving of this event is disabled.";
            }         
          }else{
              args.e.moveDisabled = true;
              args.e.rightClickDisabled  = true;
              args.e.deleteDisabled = true;
              args.e.doubleClickDisabled  = true;
              args.e.clickDisabled = true;
              args.e.resizeDisabled = true;
              args.e.cssClass = "disabled";
              args.e.barColor = "#9a0";
              args.e.barBackColor = "#ac0";
              args.e.bubbleHtml = "Moving of this event is disabled.";
          }
        },
        onBeforeCellRender: function(args) {
          if (args.cell.start < new DayPilot.Date.today() || args.cell.resource == "WA") {
              args.cell.disabled = true;
              args.cell.backColor = "#cccccc96";
          }
        },
        contextMenu: new DayPilot.Menu({
          items: [
            // {
            //   text: "Delete",
            //   onClick: function(args) {
            //       store.dispatch('schedulesCabinets/destroy', args.source.cache.id)
            //       .then(res => {
            //         store.dispatch('schedulesCabinets/index')
            //           console.log(res);
            //         })
            //       .catch(err => {
            //         console.log(err);
            //       })
            //   }
            // },
            {
              text: "-"
            },
            {
              text: "Занята",
              icon: "icon icon-blue",
              color: "#66c33a",
              onClick: function(args) {
                  let form = {};
                  form.start= args.source.cache.start,
                  form.end = args.source.cache.end,
                  form.id = args.source.cache.id,
                  form.resource = args.source.cache.resource,
                  form.bunk_id = args.source.cache.resource,
                  form.text = args.source.cache.text,
                  form.color = args.item.color,
                  store.dispatch('schedulesCabinets/update',form)
                    .then(res => {
                      console.log(res);
                    })
                    .catch(err => {
                      console.log(err);
                    })
              }
            },
            {
              text: "Забронировано",
              icon: "icon icon-green",
              color: "#fff385",
              onClick: function(args) {
                  let form = {};
                  form.start= args.source.cache.start,
                  form.end = args.source.cache.end,
                  form.id = args.source.cache.id,
                  form.resource = args.source.cache.resource,
                  form.bunk_id = args.source.cache.resource,
                  form.text = args.source.cache.text,
                  form.color = args.item.color,
                  store.dispatch('schedulesCabinets/update',form)
                    .then(res => {
                        console.log(res);
                      })
                    .catch(err => {
                      console.log(err);
                    })
              }
            },
            {
              text: "Карантин",
              icon: "icon icon-yellow",
              color: "#cc0000",
              onClick: function(args) {
                  let form = {};
                  form.start= args.source.cache.start,
                  form.end = args.source.cache.end,
                  form.id = args.source.cache.id,
                  form.resource = args.source.cache.resource,
                  form.bunk_id = args.source.cache.resource,
                  form.text = args.source.cache.text,
                  form.color = args.item.color,
                  store.dispatch('schedulesCabinets/update',form)
                    .then(res => {
                        console.log(res);
                      })
                    .catch(err => {
                      console.log(err);
                    })
              }
            },
          ]
        })
      }
    };
  },

  watch:{
      dataroom: {
        handler: function(val,oldVal){
          var data = JSON.parse( JSON.stringify(this.dataroom));
          this.scheduler.update({ resources: data });
        },
        deep: true
      },
      list: {
        handler: function(val,oldVal){
          var data2 = JSON.parse( JSON.stringify(this.list));
          this.scheduler.update({ events: data2 });
        },
        deep: true
      }
  },
  methods: {
    ...mapActions({
            updateList: 'schedulesCabinets/index',
            updateDataroom: 'hospitalRooms/indexbunk',
        }),
    fetchData(){
        this.updateDataroom().then(res => {
        }).catch(err => {
        });
        this.updateList().then(res => {
            this.$emit('loadOff');
        }).catch(err => {
        });
    },
    fetchAgain(){
        this.$emit('loadOn');
        this.scheduler.scrollTo(new DayPilot.Date.today());
        this.fetchData();
    },
    updateColor: function(e, color) {
      var dp = this.scheduler;
      e.data.color = color;
      dp.events.update(e);
      dp.message("Color updated");
    }
  },
};
</script>

<style>
.scheduler_default_tree_image_no_children {
  display: none;
}
.scheduler_default_rowheader_inner_indent {
  margin-left: 10px !important;
}
.menu_default_main{
  z-index: 12000 !important;
}

</style>
